import Container_BubbleAreaComponent from '../components/Container/viewer/skinComps/BubbleArea/BubbleArea.skin';


const Container_BubbleArea = {
  component: Container_BubbleAreaComponent
};


export const components = {
  ['Container_BubbleArea']: Container_BubbleArea
};

